// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-home-old-tsx": () => import("./../../../src/pages/homeOld.tsx" /* webpackChunkName: "component---src-pages-home-old-tsx" */),
  "component---src-pages-homev-2-tsx": () => import("./../../../src/pages/homev2.tsx" /* webpackChunkName: "component---src-pages-homev-2-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-meeting-tsx": () => import("./../../../src/pages/meeting.tsx" /* webpackChunkName: "component---src-pages-meeting-tsx" */),
  "component---src-pages-origins-tsx": () => import("./../../../src/pages/origins.tsx" /* webpackChunkName: "component---src-pages-origins-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-solutions-provider-tsx": () => import("./../../../src/pages/solutions-provider.tsx" /* webpackChunkName: "component---src-pages-solutions-provider-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-thank-you-agenda-tsx": () => import("./../../../src/pages/thank-you-agenda.tsx" /* webpackChunkName: "component---src-pages-thank-you-agenda-tsx" */),
  "component---src-pages-thank-you-for-contacting-us-tsx": () => import("./../../../src/pages/thank-you-for-contacting-us.tsx" /* webpackChunkName: "component---src-pages-thank-you-for-contacting-us-tsx" */),
  "component---src-pages-thank-you-for-signing-up-tsx": () => import("./../../../src/pages/thank-you-for-signing-up.tsx" /* webpackChunkName: "component---src-pages-thank-you-for-signing-up-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-pages-translation-interpretation-services-tsx": () => import("./../../../src/pages/Translation-Interpretation-Services.tsx" /* webpackChunkName: "component---src-pages-translation-interpretation-services-tsx" */),
  "component---src-pages-translator-tsx": () => import("./../../../src/pages/translator.tsx" /* webpackChunkName: "component---src-pages-translator-tsx" */),
  "component---src-pages-transportation-services-tsx": () => import("./../../../src/pages/Transportation-Services.tsx" /* webpackChunkName: "component---src-pages-transportation-services-tsx" */)
}

